import React from "react";
import { Link } from "react-router-dom";

const CardContact = ({ image, title, path }) => {
  return (
    <div className="">
      <div className="container bg-white shadow-md rounded-full md:w-16 md:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 flex justify-center items-center">
        <div className="bg-sky-400 md:w-14 md:h-14 lg:w-16 lg:h-16 xl:w-20 xl:h-20 shadow-md transition-transform hover:scale-105 duration-300 shadow-indigo-500 hover:bg-sky-500 flex justify-center items-center rounded-full ">
          {path ? (
            <Link to={path} className="no-underline">
              <img src={image} alt={title} className="text-start flex justify-center items-center p-2 w-16" />
            </Link>
          ) : (
            <img src="your-image-url-here" alt={title} className="text-start p-2 flex items-center" />
          )}
        </div>
      </div>
      <p className="flex justify-center text-white pt-2 font-Poppins">{title}</p>
    </div>
  );
};

export default CardContact;
